<template>
  <div class="py-4 d-flex justify-content-center align-items-center">
    <b-spinner variant="dark" label="Spinning"></b-spinner>
  </div>
</template>

<script>
import VerifyIntegrationService from "@/services/resources/VerifyIntegrationService";
const service = VerifyIntegrationService.build();


export default {
  methods: {
    fetchUser(token) {
      var data = {
        id: `/${token}`,
      };
      service
        .createId(data)
        .then((resp) => {
          this.$store.dispatch("blogSelected2", {token: resp.auth});
        })
        .catch((error) => {
          this.$bvToast.toast("Token inválido", {
            title: "Integrações",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          setTimeout(() => {
            window.history.back();
          }, 5000);
        })
        .finally(() => {});
    },
  },
  mounted() {
    var token = this.$route.params.integration_token;
    this.fetchUser(token);
  },
};
</script>

<style lang="scss">
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 10px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}
.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.toast-body {
  color: #5f5c6b !important;
}
.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}
.toast-header strong::before {
  content: "";
  background: url("../assets/img/icons/notify_default.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}
// sucesso
.b-toast-success .toast-header strong {
  color: var(--greenn);
}
.b-toast-success .toast-header strong::before {
  background: url("../assets/img/icons/notify_success.svg") no-repeat center;
}
.b-toast-success .toast {
  background-color: #f2fefa !important;
  border-color: var(--greenn) !important;
  color: var(--greenn) !important;
}
.b-toast-success .toast .toast-header {
  color: var(--greenn) !important;
  background-color: #f2fefa !important;
  border: none !important;
}
.b-toast-success.b-toast-solid .toast {
  background-color: #f2fefa !important;
}

// info
.b-toast-info .toast-header strong {
  color: var(--bluee);
}
.b-toast-info .toast-header strong::before {
  background: url("../assets/img/icons/notify_info.svg") no-repeat center;
}
.b-toast-info .toast {
  background-color: #f4f5fd !important;
  border-color: var(--bluee) !important;
  color: var(--bluee) !important;
}
.b-toast-info .toast .toast-header {
  color: var(--bluee) !important;
  background-color: #f4f5fd !important;
  border: none !important;
}
.b-toast-info.b-toast-solid .toast {
  background-color: #f4f5fd !important;
}

// danger
.b-toast-danger .toast-header strong {
  color: var(--red);
}
.b-toast-danger .toast-header strong::before {
  background: url("../assets/img/icons/notify_danger.svg") no-repeat center;
}
.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: var(--red) !important;
  color: var(--red) !important;
}
.b-toast-danger .toast .toast-header {
  color: var(--red) !important;
  background-color: #fff3f5 !important;
  border: none !important;
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// warning
.b-toast-warning .toast-header strong {
  color: var(--orange);
}
.b-toast-warning .toast-header strong::before {
  background: url("../assets/img/icons/notify_warning.svg") no-repeat center;
}
.b-toast-warning .toast {
  background-color: #fffcf5 !important;
  border-color: var(--orange) !important;
  color: var(--orange) !important;
}
.b-toast-warning .toast .toast-header {
  color: var(--orange) !important;
  background-color: #fffcf5 !important;
  border: none !important;
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fffcf5 !important;
}

// default
.toast-header strong {
  color: var(--gray01);
}
.toast-header strong::before {
  background: url("../assets/img/icons/notify_default.svg") no-repeat center;
}
.toast {
  background-color: #f6f6f7 !important;
  border-color: var(--gray01) !important;
  color: var(--gray01) !important;
}
.toast .toast-header {
  color: var(--gray01) !important;
  background-color: #f6f6f7 !important;
  border: none !important;
}
.b-toast-solid .toast {
  background-color: #f6f6f7 !important;
}
</style>
